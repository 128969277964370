// Dashboard.js
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import { useAuth } from '../contexts/AuthContext';
import api, { setAuthToken } from '../services/api'; // Importer api et setAuthToken
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';

// Enregistrer les composants nécessaires de Chart.js
Chart.register(...registerables);

// Fonction pour générer des couleurs aléatoires
const generateRandomColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const hue = (i * 137.5) % 360; // Utilise le nombre d'or pour une distribution uniforme des couleurs
    colors.push(`hsl(${hue}, 70%, 60%)`);
  }
  return colors;
};

const Dashboard = ({ selectedPeriod }) => {
  const { user } = useAuth();
  const [kpiData, setKpiData] = useState(null);
  const [productStats, setProductStats] = useState(null);
  const [selectedTab, setSelectedTab] = useState('global');
  const [isAdmin, setIsAdmin] = useState(false);
  const chartsRef = useRef({});
  const [shelfStats, setShelfStats] = useState(null);
  const [sessionStats, setSessionStats] = useState(null);
  const [missingProductStats, setMissingProductStats] = useState(null);

  // Vision Metrics
  const [visionMetricsData, setVisionMetricsData] = useState(null);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [selectedResolution, setSelectedResolution] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedRack, setSelectedRack] = useState(null);
  const [selectedShelf, setSelectedShelf] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [productClasses, setProductClasses] = useState([]);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);

  useEffect(() => {
    if (user && user.roles) {
      setIsAdmin(user.roles.includes('admin'));
    }
  }, [user]);

  useEffect(() => {
    if (user?.token) {
      setAuthToken(user.token);
    } else {
      setAuthToken(null);
    }
  }, [user?.token]);

  // Fonction pour charger la liste des utilisateurs
  const fetchUsers = async () => {
    try {
      const response = await api.get('/vision-metrics/session-users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fonction pour charger les sessions
  const fetchSessions = async (userId = null) => {
    setIsLoadingSessions(true);
    try {
      const response = await api.get('/vision-metrics/sessions', {
        params: { user_id: userId }
      });
      setSessions(response.data);
    } catch (error) {
      console.error('Error fetching sessions:', error);
    } finally {
      setIsLoadingSessions(false);
    }
  };

  // Fonction pour charger les classes de produits
  const fetchProductClasses = async () => {
    try {
      const response = await api.get('/vision-metrics/product-classes');
      setProductClasses(response.data);
    } catch (error) {
      console.error('Error fetching product classes:', error);
    }
  };

  // Fonction pour charger les métriques
  const fetchVisionMetrics = async () => {
    try {
      const params = new URLSearchParams();
      if (selectedSessions.length > 0) {
        selectedSessions.forEach(sessionId => {
          params.append('session_ids', sessionId);
        });
      }
      if (dateRange.start) params.append('start_date', dateRange.start);
      if (dateRange.end) params.append('end_date', dateRange.end);
      if (selectedResolution) params.append('resolution', selectedResolution);
      if (selectedClass) params.append('class_name', selectedClass);
      if (selectedRack) params.append('rack_id', selectedRack);
      if (selectedShelf) params.append('shelf_id', selectedShelf);

      const [summaryResponse, timelineResponse] = await Promise.all([
        api.get(`/detection-metrics/summary?${params}`),
        api.get(`/detection-metrics/timeline?${params}`)
      ]);

      setVisionMetricsData({
        summary: summaryResponse.data,
        timeline: timelineResponse.data
      });
    } catch (error) {
      console.error('Error fetching vision metrics:', error);
    }
  };

  // Gestionnaire de changement d'utilisateur
  const handleUserChange = async (e) => {
    const userId = e.target.value ? parseInt(e.target.value) : null;
    setSelectedUser(userId);
    setSelectedSessions([]); // Réinitialiser les sessions sélectionnées
    await fetchSessions(userId);
  };

  // Composant des filtres Vision AI
  const renderVisionAIFilters = () => (
    <div className="box">
      <h3 className="title is-5">Filters</h3>
      <div className="columns is-multiline">
        {/* Filtre utilisateur */}
        <div className="column is-3">
          <div className="field">
            <label className="label">User</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select 
                  value={selectedUser || ''} 
                  onChange={handleUserChange}
                >
                  <option value="">All Users</option>
                  {users.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.session_count} sessions)
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Filtre sessions */}
        <div className="column is-3">
          <div className="field">
            <label className="label">Sessions</label>
            <div className="control">
              <div className="select is-multiple is-fullwidth">
                <select 
                  multiple 
                  value={selectedSessions}
                  onChange={(e) => setSelectedSessions(
                    Array.from(e.target.selectedOptions, option => option.value)
                  )}
                  disabled={isLoadingSessions}
                >
                  {isLoadingSessions ? (
                    <option>Loading sessions...</option>
                  ) : (
                    sessions.map(session => (
                      <option key={session.id} value={session.id}>
                        {session.name} - {session.date}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Filtre résolution */}
        <div className="column is-3">
          <div className="field">
            <label className="label">Resolution</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  value={selectedResolution || ''}
                  onChange={(e) => setSelectedResolution(e.target.value || null)}
                >
                  <option value="">All Resolutions</option>
                  <option value="1920x1080">1920x1080</option>
                  <option value="1280x720">1280x720</option>
                  <option value="854x480">854x480</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Filtre classes de produits */}
        <div className="column is-3">
          <div className="field">
            <label className="label">Product Class</label>
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  value={selectedClass || ''}
                  onChange={(e) => setSelectedClass(e.target.value || null)}
                >
                  <option value="">All Classes</option>
                  {productClasses.map(product => (
                    <option key={product.id} value={product.class_id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Filtre période */}
        <div className="column is-3">
          <div className="field">
            <label className="label">Date Range</label>
            <div className="control">
              <input
                type="date"
                className="input"
                value={dateRange.start || ''}
                onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
              />
            </div>
            <div className="control mt-2">
              <input
                type="date"
                className="input"
                value={dateRange.end || ''}
                onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.token) return;

      try {
        // Toujours charger les données KPI de base
        const kpiResponse = await api.get(`/kpi?period=${selectedPeriod}`);
        setKpiData(kpiResponse.data);

        const productStatsResponse = await api.get(`/kpi-product?period=${selectedPeriod}`);
        setProductStats(productStatsResponse.data || {});

        const shelfStatsResponse = await api.get(`/kpi-shelf?period=${selectedPeriod}`);
        setShelfStats(shelfStatsResponse.data);

        const sessionStatsResponse = await api.get(`/kpi-session?period=${selectedPeriod}`);
        setSessionStats(sessionStatsResponse.data);

        try {
            const missingProductStatsResponse = await api.get(`/kpi-missing-products?period=${selectedPeriod}`);
            setMissingProductStats(missingProductStatsResponse.data || {});
        } catch (error) {
            console.error('Error fetching missing product stats:', error);
            setMissingProductStats(null);
        }

        if (selectedTab === 'visionAI') {
          await fetchVisionMetrics();
        }


      } catch (error) {
        console.error('Error fetching KPI data:', error);
      }
    };

    fetchData();
  }, [selectedTab, selectedPeriod, selectedSessions, dateRange, selectedResolution, selectedClass, selectedRack, selectedShelf, user?.token]);

  // Effet pour charger les données initiales des filtres
  useEffect(() => {
    if (selectedTab === 'visionAI') {
      fetchUsers();
      fetchSessions();
      fetchProductClasses();
    }
  }, [selectedTab]);
  

  const destroyCharts = useCallback(() => {
    Object.values(chartsRef.current).forEach(chart => {
      if (chart) {
        chart.destroy();
      }
    });
    chartsRef.current = {};
  }, []);

  const renderCharts = useCallback(() => {
    destroyCharts();

    if (selectedTab === 'missingProducts' && missingProductStats && typeof missingProductStats === 'object') {

      console.log('Selected Tab:', selectedTab);
      console.log('Missing Product Stats:', missingProductStats);
      
      // Graphique des zones problématiques
      const ctxShelf = document.getElementById('shelfProblemChart')?.getContext('2d');
      const shelfStatsData = missingProductStats?.shelf_statistics || [];
      if (ctxShelf && Array.isArray(shelfStatsData) && shelfStatsData.length > 0) {
          chartsRef.current.shelfProblemChart = new Chart(ctxShelf, {
              type: 'bar',
              data: {
                  labels: shelfStatsData.map((s) => s.shelf_name),
                  datasets: [
                      {
                          label: 'Problem Score',
                          data: shelfStatsData.map((s) => s.problem_score),
                          backgroundColor: generateRandomColors(shelfStatsData.length),
                      },
                  ],
              },
              options: {
                  indexAxis: 'y',
                  plugins: {
                      tooltip: {
                          callbacks: {
                              label: (context) => {
                                  const stat = shelfStatsData[context.dataIndex];
                                  return [
                                      `Problem Score: ${context.raw.toFixed(2)}`,
                                      `Reports: ${stat.missing_reports}`,
                                      `Missing Items: ${stat.total_missing}`,
                                  ];
                              },
                          },
                      },
                  },
              },
          });
      }


      // Graphique de la chronologie (Timeline)
      const ctxTimeline = document.getElementById('missingProductsTimelineChart')?.getContext('2d');
      const dailyTrends = missingProductStats?.daily_trends || [];
      if (ctxTimeline && Array.isArray(dailyTrends) && dailyTrends.length > 0) {
          chartsRef.current.missingProductsTimelineChart = new Chart(ctxTimeline, {
              type: 'line',
              data: {
                  labels: dailyTrends.map((trend) => trend.date),
                  datasets: [
                      {
                          label: 'Identified Products',
                          data: dailyTrends.map((trend) => trend.identified_count),
                          borderColor: 'rgb(75, 192, 192)',
                          fill: false,
                      },
                      {
                          label: 'Unidentified Products',
                          data: dailyTrends.map((trend) => trend.unidentified_count),
                          borderColor: 'rgb(255, 99, 132)',
                          fill: false,
                      },
                  ],
              },
              options: {
                  responsive: true,
                  plugins: {
                      title: {
                          display: true,
                          text: 'Missing Products Over Time',
                      },
                  },
                  scales: {
                      y: {
                          beginAtZero: true,
                          title: {
                              display: true,
                              text: 'Number of Products',
                          },
                      },
                      x: {
                          title: {
                              display: true,
                              text: 'Date',
                          },
                      },
                  },
              },
          });
      }

      // Graphique d'activité des rapporteurs
      const ctxReporter = document.getElementById('reporterActivityChart')?.getContext('2d');
      const reporterStats = missingProductStats?.reporter_statistics || [];
      if (ctxReporter && Array.isArray(reporterStats) && reporterStats.length > 0) {
          chartsRef.current.reporterActivityChart = new Chart(ctxReporter, {
              type: 'bar',
              data: {
                  labels: reporterStats.map((stat) => stat.name),
                  datasets: [
                      {
                          label: 'Reports Count',
                          data: reporterStats.map((stat) => stat.reports_count),
                          backgroundColor: generateRandomColors(reporterStats.length),
                      },
                  ],
              },
              options: {
                  responsive: true,
                  plugins: {
                      title: {
                          display: true,
                          text: 'Reports by User',
                      },
                  },
                  scales: {
                      y: {
                          beginAtZero: true,
                      },
                  },
              },
          });
      }

      // Graphique de distribution horaire
      const ctxTime = document.getElementById('timeDistributionChart')?.getContext('2d');
      const hourlyDistribution = missingProductStats?.hourly_distribution || [];
      if (ctxTime && Array.isArray(hourlyDistribution) && hourlyDistribution.length > 0) {
          chartsRef.current.timeDistributionChart = new Chart(ctxTime, {
              type: 'line',
              data: {
                  labels: hourlyDistribution.map((stat) =>
                      `${String(stat.hour).padStart(2, '0')}:00`
                  ),
                  datasets: [
                      {
                          label: 'Reports Count',
                          data: hourlyDistribution.map((stat) => stat.count),
                          borderColor: 'rgb(54, 162, 235)',
                          fill: false,
                      },
                  ],
              },
              options: {
                  responsive: true,
                  plugins: {
                      title: {
                          display: true,
                          text: 'Reports Distribution by Hour',
                      },
                  },
                  scales: {
                      y: {
                          beginAtZero: true,
                      },
                      x: {
                          title: {
                              display: true,
                              text: 'Hour of Day',
                          },
                      },
                  },
              },
          });
      }

      // Graphique des catégories de produits
      const ctxCategories = document.getElementById('productCategoriesChart')?.getContext('2d');
      const topMissingProducts = missingProductStats?.top_missing_products || [];
      if (ctxCategories && Array.isArray(topMissingProducts) && topMissingProducts.length > 0) {
          chartsRef.current.productCategoriesChart = new Chart(ctxCategories, {
              type: 'doughnut',
              data: {
                  labels: topMissingProducts.map((product) => product.product_name),
                  datasets: [
                      {
                          data: topMissingProducts.map((product) => product.total_missing),
                          backgroundColor: generateRandomColors(topMissingProducts.length),
                      },
                  ],
              },
              options: {
                  responsive: true,
                  plugins: {
                      title: {
                          display: true,
                          text: 'Most Reported Missing Products',
                      },
                      legend: {
                          position: 'bottom',
                      },
                  },
              },
          });
      }

    }

    if (selectedTab === 'global') {
      const ctx1 = document.getElementById('inventorySessionsChart')?.getContext('2d');
      if (ctx1 && kpiData && kpiData.labels && kpiData.inventorySessionCounts) {
        chartsRef.current.inventorySessionsChart = new Chart(ctx1, {
          type: 'line',
          data: {
            labels: kpiData.labels,
            datasets: [{
              label: 'Number of Inventory Sessions',
              data: kpiData.inventorySessionCounts,
              borderColor: '#FF5733',
              fill: false,
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx2 = document.getElementById('detectionDurationChart')?.getContext('2d');
      if (ctx2 && kpiData && kpiData.shelfNames && kpiData.avgDetectionDurations) {
        const backgroundColors = generateRandomColors(kpiData.shelfNames.length);
        chartsRef.current.detectionDurationChart = new Chart(ctx2, {
          type: 'pie',
          data: {
            labels: kpiData.shelfNames,
            datasets: [{
              data: kpiData.avgDetectionDurations,
              backgroundColor: backgroundColors
            }]
          }
        });
      }
    }

    if (selectedTab === 'adjustments') {
      const ctx3 = document.getElementById('inventoryAdjustmentsChart')?.getContext('2d');
      if (ctx3 && kpiData && kpiData.labels && kpiData.inventoryAdjustmentsCount) {
        const backgroundColors = generateRandomColors(kpiData.labels.length);
        chartsRef.current.inventoryAdjustmentsChart = new Chart(ctx3, {
          type: 'bar',
          data: {
            labels: kpiData.labels,
            datasets: [{
              label: 'Number of Adjustments',
              data: kpiData.inventoryAdjustmentsCount,
              backgroundColor: backgroundColors,
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx4 = document.getElementById('productAdjustmentPercentageChart')?.getContext('2d');
      if (ctx4 && kpiData && kpiData.labels && kpiData.adjustedProductsPercentage) {
        chartsRef.current.productAdjustmentPercentageChart = new Chart(ctx4, {
          type: 'bar',
          data: {
            labels: kpiData.labels,
            datasets: [
              {
                label: 'Percentage of Adjusted Products',
                data: kpiData.adjustedProductsPercentage,
                backgroundColor: '#FF5733',
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                  callback: (value) => `${value}%`,
                },
              },
            },
          },
        });
      }

      const ctx5 = document.getElementById('adjustmentReasonsChart')?.getContext('2d');
      if (ctx5 && kpiData && kpiData.adjustmentReasons) {
        const filteredReasons = Object.entries(kpiData.adjustmentReasons || {})
          .filter(([, count]) => count > 0);

        const labels = filteredReasons.map(([reason]) => reason);
        const data = filteredReasons.map(([, count]) => count);
        const backgroundColors = generateRandomColors(labels.length);

        chartsRef.current.adjustmentReasonsChart = new Chart(ctx5, {
          type: 'pie',
          data: {
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: backgroundColors,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Reasons for Non-Zero Adjustments'
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    const label = context.label || '';
                    const value = context.parsed || 0;
                    const total = context.dataset.data.reduce((acc, current) => acc + current, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${label}: ${value} (${percentage}%)`;
                  }
                }
              }
            }
          }
        });
      }

      const ctx6 = document.getElementById('userAdjustmentsChart')?.getContext('2d');
      if (ctx6 && kpiData && kpiData.userAdjustments) {
        chartsRef.current.userAdjustmentsChart = new Chart(ctx6, {
          type: 'bar',
          data: {
            labels: Object.keys(kpiData.userAdjustments || {}),
            datasets: [
              {
                label: 'Number of Adjustments',
                data: Object.values(kpiData.userAdjustments || {}),
                backgroundColor: '#FF5733',
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    }

    if (selectedTab === 'productStats') {
      const ctx7 = document.getElementById('totalQuantityByProductChart')?.getContext('2d');
      if (ctx7 && productStats && productStats.totalQuantityByProduct) {
        const labels = Object.keys(productStats.totalQuantityByProduct || {});
        const data = Object.values(productStats.totalQuantityByProduct || {});
        const backgroundColors = generateRandomColors(labels.length);

        chartsRef.current.totalQuantityByProductChart = new Chart(ctx7, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [{
              label: 'Total Quantity',
              data: data,
              backgroundColor: backgroundColors,
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false
              }
            }
          }
        });
      }

      const ctx8 = document.getElementById('quantityVariationOverTimeChart')?.getContext('2d');
      if (ctx8 && productStats && productStats.quantityVariationOverTime) {
        const labels = Object.keys(productStats.quantityVariationOverTime);
        const products = Object.keys(productStats.quantityVariationOverTime[labels[0]]);
        const backgroundColors = generateRandomColors(products.length);

        const datasets = products.map((product, index) => {
          return {
            label: product,
            data: labels.map(date => productStats.quantityVariationOverTime[date][product]),
            borderColor: backgroundColors[index],
            fill: false,
          };
        });

        chartsRef.current.quantityVariationOverTimeChart = new Chart(ctx8, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }

      const ctx9 = document.getElementById('mostAdjustedProductsChart')?.getContext('2d');
      if (ctx9 && productStats && productStats.mostAdjustedProducts) {
        const labels = Object.keys(productStats.mostAdjustedProducts || {});
        const data = Object.values(productStats.mostAdjustedProducts || {});
        const backgroundColors = generateRandomColors(labels.length);

        chartsRef.current.mostAdjustedProductsChart = new Chart(ctx9, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Number of Adjustments',
                data: data,
                backgroundColor: backgroundColors,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false
              }
            }
          },
        });
      }
    }

    if (selectedTab === 'shelfStats' && shelfStats) {
      const ctx = document.getElementById('productsPerShelfChart')?.getContext('2d');
      if (ctx && shelfStats.shelfNames && shelfStats.productCounts) {
        chartsRef.current.productsPerShelfChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: shelfStats.shelfNames,
            datasets: [{
              label: 'Number of Products',
              data: shelfStats.productCounts,
              backgroundColor: '#FF5733',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx2 = document.getElementById('detectedVsAdjustedChart')?.getContext('2d');
      if (ctx2 && shelfStats.shelfNamesDA && shelfStats.detectedQuantities && shelfStats.adjustedQuantities) {
        chartsRef.current.detectedVsAdjustedChart = new Chart(ctx2, {
          type: 'bar',
          data: {
            labels: shelfStats.shelfNamesDA,
            datasets: [
              {
                label: 'Detected Quantity',
                data: shelfStats.detectedQuantities,
                backgroundColor: '#FF5733',
              },
              {
                label: 'Adjusted Quantity',
                data: shelfStats.adjustedQuantities,
                backgroundColor: '#33FF57',
              }
            ]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }

      const ctx3 = document.getElementById('shelfOccupancyChart')?.getContext('2d');
      if (ctx3 && shelfStats.shelfNamesOccupancy && shelfStats.occupancyRates) {
        chartsRef.current.shelfOccupancyChart = new Chart(ctx3, {
          type: 'bar',
          data: {
            labels: shelfStats.shelfNamesOccupancy,
            datasets: [{
              label: 'Occupancy Rate (%)',
              data: shelfStats.occupancyRates,
              backgroundColor: '#3357FF',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                  callback: function(value) {
                    return value + '%';
                  }
                }
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return context.parsed.y.toFixed(2) + '%';
                  }
                }
              }
            }
          }
        });
      }
    }

    if (selectedTab === 'sessionStats' && sessionStats && sessionStats.sessions) {
      const ctx1 = document.getElementById('sessionDurationChart')?.getContext('2d');
      if (ctx1) {
        chartsRef.current.sessionDurationChart = new Chart(ctx1, {
          type: 'bar',
          data: {
            labels: sessionStats.sessions.map(session => session.name),
            datasets: [{
              label: 'Duration (minutes)',
              data: sessionStats.sessions.map(session => session.duration / 60),
              backgroundColor: '#4e73df',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Duration (minutes)'
                }
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Session Duration'
              }
            }
          }
        });
      }

      const ctx2 = document.getElementById('sessionProductCountChart')?.getContext('2d');
      if (ctx2) {
        chartsRef.current.sessionProductCountChart = new Chart(ctx2, {
          type: 'bar',
          data: {
            labels: sessionStats.sessions.map(session => session.name),
            datasets: [{
              label: 'Product Count',
              data: sessionStats.sessions.map(session => session.product_count),
              backgroundColor: '#1cc88a',
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Product Count'
                }
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Products Counted per Session'
              }
            }
          }
        });
      }

      const ctx3 = document.getElementById('sessionTrendChart')?.getContext('2d');
      if (ctx3) {
        chartsRef.current.sessionTrendChart = new Chart(ctx3, {
          type: 'line',
          data: {
            labels: sessionStats.sessions.map(session => session.start_time),
            datasets: [{
              label: 'Duration (minutes)',
              data: sessionStats.sessions.map(session => session.duration / 60),
              borderColor: '#4e73df',
              fill: false
            }, {
              label: 'Product Count',
              data: sessionStats.sessions.map(session => session.product_count),
              borderColor: '#1cc88a',
              fill: false
            }]
          },
          options: {
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day'
                }
              },
              y: {
                beginAtZero: true
              }
            },
            plugins: {
              title: {
                display: true,
                text: 'Session Trends Over Time'
              }
            }
          }
        });
      }
    }



  }, [kpiData, productStats, shelfStats, sessionStats, selectedTab, destroyCharts]);



  const renderVisionAICharts = useCallback(() => {
    if (!visionMetricsData) return;
  
    // Confidence Timeline Chart
    const ctxTimeline = document.getElementById('confidenceTimelineChart')?.getContext('2d');
    if (ctxTimeline) {
      chartsRef.current.confidenceTimeline = new Chart(ctxTimeline, {
        type: 'line',
        data: {
          labels: visionMetricsData.timeline.map(point => new Date(point.time_bucket).toLocaleTimeString()),
          datasets: [
            {
              label: 'Average Confidence',
              data: visionMetricsData.timeline.map(point => point.avg_confidence),
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            },
            {
              label: 'FPS',
              data: visionMetricsData.timeline.map(point => point.avg_fps),
              borderColor: 'rgb(255, 99, 132)',
              yAxisID: 'fps'
            }
          ]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              title: {
                display: true,
                text: 'Confidence %'
              }
            },
            fps: {
              position: 'right',
              beginAtZero: true,
              title: {
                display: true,
                text: 'FPS'
              }
            }
          }
        }
      });
    }
  
    // Class Performance Chart
    const ctxClass = document.getElementById('classPerformanceChart')?.getContext('2d');
    if (ctxClass) {
      chartsRef.current.classPerformance = new Chart(ctxClass, {
        type: 'bar',
        data: {
          labels: visionMetricsData.summary.class_stats.map(stat => stat.class_name),
          datasets: [
            {
              label: 'Average Confidence',
              data: visionMetricsData.summary.class_stats.map(stat => stat.avg_confidence),
              backgroundColor: generateRandomColors(visionMetricsData.summary.class_stats.length)
            }
          ]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100
            }
          }
        }
      });
    }
  
    // Resolution Impact Chart
    const ctxResolution = document.getElementById('resolutionImpactChart')?.getContext('2d');
    if (ctxResolution && visionMetricsData.summary.resolution_stats.length > 0) {
      chartsRef.current.resolutionImpact = new Chart(ctxResolution, {
        type: 'bubble',
        data: {
          datasets: [{
            label: 'Resolution Performance',
            data: visionMetricsData.summary.resolution_stats.map(stat => ({
              x: stat.stream_width,
              y: stat.avg_confidence,
              r: stat.detection_count / 100 // Ajuster la taille des bulles selon vos besoins
            })),
            backgroundColor: generateRandomColors(visionMetricsData.summary.resolution_stats.length)
          }]
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Resolution Width'
              }
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Confidence'
              }
            }
          }
        }
      });
    }
  
    // Stream Performance Chart
    const ctxStream = document.getElementById('streamPerformanceChart')?.getContext('2d');
    if (ctxStream) {
      chartsRef.current.streamPerformance = new Chart(ctxStream, {
        type: 'scatter',
        data: {
          datasets: [{
            label: 'Bitrate vs Confidence',
            data: visionMetricsData.timeline.map(point => ({
              x: point.avg_bitrate / 1000, // Convert to Kbps
              y: point.avg_confidence
            })),
            backgroundColor: 'rgba(75, 192, 192, 0.5)'
          }]
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Bitrate (Kbps)'
              }
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Confidence'
              }
            }
          }
        }
      });
    }
  }, [visionMetricsData]);
  
  // Ajoutez cette ligne dans useEffect qui gère les rendus de graphiques
  useEffect(() => {
    if (selectedTab === 'visionAI' && visionMetricsData) {
      renderVisionAICharts();
    }
  }, [selectedTab, visionMetricsData, renderVisionAICharts]);

  useEffect(() => {
    if (kpiData && productStats && shelfStats && sessionStats) {

      if (selectedTab === 'missingProducts' && !missingProductStats) {
          console.warn('Missing Product Stats is undefined or null.');
          return;
      }
      renderCharts();
    }
  }, [kpiData, productStats, shelfStats, sessionStats, missingProductStats, renderCharts]);

  if (!kpiData || !productStats || !shelfStats || !sessionStats) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="title">Dashboard</h1>
      <div className="tabs is-toggle is-fullwidth">
        <ul>
          <li className={selectedTab === 'global' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('global')}>Global view</a>
          </li>
          <li className={selectedTab === 'adjustments' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('adjustments')}>Inventory adjustments</a>
          </li>
          <li className={selectedTab === 'missingProducts' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('missingProducts')}>Missing Products</a>
          </li>
          <li className={selectedTab === 'productStats' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('productStats')}>Product statistics</a>
          </li>
          <li className={selectedTab === 'shelfStats' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('shelfStats')}>Shelf statistics</a>
          </li>
          <li className={selectedTab === 'sessionStats' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('sessionStats')}>Inventory session statistics</a>
          </li>
          <li className={selectedTab === 'visionAI' ? 'is-active' : ''}>
            <a onClick={() => setSelectedTab('visionAI')}>Vision AI Metrics</a>
          </li>
        </ul>
      </div>

      <div className={selectedTab === 'missingProducts' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Problem Areas Overview</h3>
              <p className="subtitle is-6">Shelves with most missing product reports</p>
              <canvas id="shelfProblemChart"></canvas>
            </div>
          </div>
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Missing Products Timeline</h3>
              <p className="subtitle is-6">Identified vs Unidentified Products</p>
              <canvas id="missingProductsTimelineChart"></canvas>
            </div>
          </div>
        </div>
        
        <div className="columns">
          <div className="column is-one-third">
            <div className="box">
              <h3 className="title is-5">Reporter Activity</h3>
              <p className="subtitle is-6">Missing products reports by user</p>
              <canvas id="reporterActivityChart"></canvas>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="box">
              <h3 className="title is-5">Time Distribution</h3>
              <p className="subtitle is-6">Reports by hour of day</p>
              <canvas id="timeDistributionChart"></canvas>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="box">
              <h3 className="title is-5">Product Categories</h3>
              <p className="subtitle is-6">Most reported missing products</p>
              <canvas id="productCategoriesChart"></canvas>
            </div>
          </div>
        </div>

        <div className="box mt-4">
          <h3 className="title is-5">Missing Products Summary</h3>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="notification is-info">
                <p className="heading">Total Reports</p>
                <p className="title">{missingProductStats?.summary?.total_reports || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-warning">
                <p className="heading">Total Missing Items</p>
                <p className="title">{missingProductStats?.summary?.total_missing_items || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-success">
                <p className="heading">Identified Products</p>
                <p className="title">{missingProductStats?.summary?.identified_products || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-danger">
                <p className="heading">Unidentified Products</p>
                <p className="title">{missingProductStats?.summary?.unidentified_products || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={selectedTab === 'global' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Inventory Sessions Overview</h3>
              <p className="subtitle is-6">Number of sessions over time</p>
              <canvas id="inventorySessionsChart"></canvas>
            </div>
          </div>
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Detection Duration Distribution</h3>
              <p className="subtitle is-6">Average time spent per shelf</p>
              <canvas id="detectionDurationChart"></canvas>
            </div>
          </div>
        </div>

        <div className="box mt-4">
          <h3 className="title is-5">Global Inventory Summary</h3>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="notification is-info">
                <p className="heading">Total Sessions</p>
                <p className="title">{kpiData?.inventorySessionCounts?.reduce((a, b) => a + b, 0) || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-warning">
                <p className="heading">Average Session Duration</p>
                <p className="title">{(kpiData?.avgInventoryDurations?.reduce((a, b) => a + b, 0) / 
                  (kpiData?.avgInventoryDurations?.length || 1)).toFixed(2)} min</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-success">
                <p className="heading">Total Shelves Scanned</p>
                <p className="title">{kpiData?.shelfNames?.length || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-link">
                <p className="heading">Success Rate</p>
                <p className="title">
                  {(((kpiData?.inventorySessionCounts?.reduce((a, b) => a + b, 0) || 0) / 
                    (kpiData?.inventorySessionCounts?.length || 1)) * 100).toFixed(1)}%
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-one-third">
            <div className="box">
              <h3 className="title is-5">Performance Metrics</h3>
              <p className="subtitle is-6">Key performance indicators</p>
              <div className="content">
                <ul>
                  <li>Average detection time: {(kpiData?.avgDetectionDurations?.reduce((a, b) => a + b, 0) / 
                    (kpiData?.avgDetectionDurations?.length || 1)).toFixed(2)} seconds</li>
                  <li>Total inventory duration: {kpiData?.avgInventoryDurations?.reduce((a, b) => a + b, 0).toFixed(2)} minutes</li>
                  <li>Success rate trend: {((kpiData?.inventorySessionCounts?.slice(-1)[0] / 
                    (kpiData?.inventorySessionCounts?.slice(-2, -1)[0] || 1)) * 100).toFixed(1)}% vs previous</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="column is-two-thirds">
            <div className="box">
              <h3 className="title is-5">Session Distribution</h3>
              <p className="subtitle is-6">Distribution of inventory sessions by time period</p>
              {/* Ici on pourrait ajouter un nouveau graphique montrant la distribution des sessions */}
              <div className="notification is-light">
                <div className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Morning</p>
                      <p className="title is-4">
                        {((kpiData?.inventorySessionCounts?.slice(0, 8).reduce((a, b) => a + b, 0) || 0) / 
                          (kpiData?.inventorySessionCounts?.reduce((a, b) => a + b, 0) || 1) * 100).toFixed(1)}%
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Afternoon</p>
                      <p className="title is-4">
                        {((kpiData?.inventorySessionCounts?.slice(8, 16).reduce((a, b) => a + b, 0) || 0) / 
                          (kpiData?.inventorySessionCounts?.reduce((a, b) => a + b, 0) || 1) * 100).toFixed(1)}%
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Evening</p>
                      <p className="title is-4">
                        {((kpiData?.inventorySessionCounts?.slice(16).reduce((a, b) => a + b, 0) || 0) / 
                          (kpiData?.inventorySessionCounts?.reduce((a, b) => a + b, 0) || 1) * 100).toFixed(1)}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={selectedTab === 'adjustments' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Adjustment Frequency</h3>
              <p className="subtitle is-6">Number of inventory adjustments over time</p>
              <canvas id="inventoryAdjustmentsChart"></canvas>
            </div>
          </div>
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Adjustment Distribution</h3>
              <p className="subtitle is-6">Percentage of products requiring adjustment</p>
              <canvas id="productAdjustmentPercentageChart"></canvas>
            </div>
          </div>
        </div>

        <div className="box mt-4">
          <h3 className="title is-5">Adjustment Summary</h3>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="notification is-info">
                <p className="heading">Total Adjustments</p>
                <p className="title">{kpiData?.inventoryAdjustmentsCount?.reduce((a, b) => a + b, 0) || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-warning">
                <p className="heading">Average Adjustment Rate</p>
                <p className="title">
                  {(kpiData?.productAdjustmentPercentage?.reduce((a, b) => a + b, 0) / 
                    (kpiData?.productAdjustmentPercentage?.length || 1)).toFixed(1)}%
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-success">
                <p className="heading">Adjustment Reasons</p>
                <p className="title">{Object.keys(kpiData?.adjustmentReasons || {}).length}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-link">
                <p className="heading">Active Adjusters</p>
                <p className="title">{Object.keys(kpiData?.userAdjustments || {}).length}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Adjustment Reasons</h3>
              <p className="subtitle is-6">Distribution of adjustment reasons</p>
              <canvas id="adjustmentReasonsChart"></canvas>
            </div>
          </div>
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">User Activity</h3>
              <p className="subtitle is-6">Adjustments by user</p>
              <canvas id="userAdjustmentsChart"></canvas>
            </div>
          </div>
        </div>
      </div>

      {/* Product Statistics Tab */}
      <div className={selectedTab === 'productStats' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Product Quantity Overview</h3>
              <p className="subtitle is-6">Total quantity distribution by product</p>
              <canvas id="totalQuantityByProductChart"></canvas>
            </div>
          </div>
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Quantity Trends</h3>
              <p className="subtitle is-6">Product quantity variations over time</p>
              <canvas id="quantityVariationOverTimeChart"></canvas>
            </div>
          </div>
        </div>

        <div className="box mt-4">
          <h3 className="title is-5">Product Statistics Summary</h3>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="notification is-info">
                <p className="heading">Total Products</p>
                <p className="title">
                  {Object.keys(productStats?.totalQuantityByProduct || {}).length}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-warning">
                <p className="heading">Total Stock</p>
                <p className="title">
                  {Object.values(productStats?.totalQuantityByProduct || {}).reduce((a, b) => a + b, 0)}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-success">
                <p className="heading">Average per Product</p>
                <p className="title">
                  {(Object.values(productStats?.totalQuantityByProduct || {}).reduce((a, b) => a + b, 0) /
                    Math.max(1, Object.keys(productStats?.totalQuantityByProduct || {}).length)).toFixed(1)}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-link">
                <p className="heading">Products in Movement</p>
                <p className="title">
                  {Object.keys(productStats?.mostAdjustedProducts || {}).length}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-full">
            <div className="box">
              <h3 className="title is-5">Product Adjustment Frequency</h3>
              <p className="subtitle is-6">Most frequently adjusted products</p>
              <canvas id="mostAdjustedProductsChart"></canvas>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-one-third">
            <div className="box">
              <h3 className="title is-5">Top Products</h3>
              <p className="subtitle is-6">Highest quantity products</p>
              <div className="content">
                <ol>
                  {Object.entries(productStats?.totalQuantityByProduct || {})
                    .sort(([, a], [, b]) => b - a)
                    .slice(0, 5)
                    .map(([name, quantity]) => (
                      <li key={name}>
                        <strong>{name}</strong>: {quantity} units
                      </li>
                    ))}
                </ol>
              </div>
            </div>
          </div>
          <div className="column is-two-thirds">
            <div className="box">
              <h3 className="title is-5">Stock Distribution</h3>
              <p className="subtitle is-6">Stock level categories</p>
              <div className="notification is-light">
                <div className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Low Stock</p>
                      <p className="title is-4">
                        {Object.values(productStats?.totalQuantityByProduct || {})
                          .filter(qty => qty < 10).length}
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Normal Stock</p>
                      <p className="title is-4">
                        {Object.values(productStats?.totalQuantityByProduct || {})
                          .filter(qty => qty >= 10 && qty < 50).length}
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">High Stock</p>
                      <p className="title is-4">
                        {Object.values(productStats?.totalQuantityByProduct || {})
                          .filter(qty => qty >= 50).length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Shelf Statistics Tab */}
      <div className={selectedTab === 'shelfStats' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Products per Shelf</h3>
              <p className="subtitle is-6">Number of products allocated to each shelf</p>
              <canvas id="productsPerShelfChart"></canvas>
            </div>
          </div>
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Detection Accuracy</h3>
              <p className="subtitle is-6">Comparison of detected vs adjusted quantities</p>
              <canvas id="detectedVsAdjustedChart"></canvas>
            </div>
          </div>
        </div>

        <div className="box mt-4">
          <h3 className="title is-5">Shelf Efficiency Summary</h3>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="notification is-info">
                <p className="heading">Total Shelves</p>
                <p className="title">{shelfStats?.shelfNames?.length || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-warning">
                <p className="heading">Average Occupancy</p>
                <p className="title">
                  {(shelfStats?.occupancyRates?.reduce((a, b) => a + b, 0) / 
                    (shelfStats?.occupancyRates?.length || 1)).toFixed(1)}%
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-success">
                <p className="heading">Fully Utilized</p>
                <p className="title">
                  {shelfStats?.occupancyRates?.filter(rate => rate >= 90).length || 0}
                </p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-danger">
                <p className="heading">Under Utilized</p>
                <p className="title">
                  {shelfStats?.occupancyRates?.filter(rate => rate < 50).length || 0}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-full">
            <div className="box">
              <h3 className="title is-5">Shelf Occupancy Overview</h3>
              <p className="subtitle is-6">Current occupancy rates for all shelves</p>
              <canvas id="shelfOccupancyChart"></canvas>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-one-third">
            <div className="box">
              <h3 className="title is-5">Shelf Utilization</h3>
              <p className="subtitle is-6">Key metrics</p>
              <div className="content">
                <ul>
                  <li>
                    <strong>Average Products/Shelf:</strong> {' '}
                    {(shelfStats?.productCounts?.reduce((a, b) => a + b, 0) / 
                      (shelfStats?.shelfNames?.length || 1)).toFixed(1)}
                  </li>
                  <li>
                    <strong>Max Capacity Used:</strong> {' '}
                    {Math.max(...(shelfStats?.occupancyRates || [0])).toFixed(1)}%
                  </li>
                  <li>
                    <strong>Min Capacity Used:</strong> {' '}
                    {Math.min(...(shelfStats?.occupancyRates || [100])).toFixed(1)}%
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column is-two-thirds">
            <div className="box">
              <h3 className="title is-5">Shelf Status Distribution</h3>
              <p className="subtitle is-6">Current shelf utilization status</p>
              <div className="notification is-light">
                <div className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Critical (&lt;30%)</p>
                      <p className="title is-4">
                        {shelfStats?.occupancyRates?.filter(rate => rate < 30).length || 0}
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Warning (30-70%)</p>
                      <p className="title is-4">
                        {shelfStats?.occupancyRates?.filter(rate => rate >= 30 && rate < 70).length || 0}
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Optimal (&gt70%)</p>
                      <p className="title is-4">
                        {shelfStats?.occupancyRates?.filter(rate => rate >= 70).length || 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={selectedTab === 'sessionStats' ? '' : 'is-hidden'}>
        <div className="columns">
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Session Duration Analysis</h3>
              <p className="subtitle is-6">Duration of inventory sessions</p>
              <canvas id="sessionDurationChart"></canvas>
            </div>
          </div>
          <div className="column is-half">
            <div className="box">
              <h3 className="title is-5">Product Count Overview</h3>
              <p className="subtitle is-6">Products counted per session</p>
              <canvas id="sessionProductCountChart"></canvas>
            </div>
          </div>
        </div>

        <div className="box mt-4">
          <h3 className="title is-5">Session Performance Summary</h3>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="notification is-info">
                <p className="heading">Total Sessions</p>
                <p className="title">{sessionStats?.totalSessions || 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-warning">
                <p className="heading">Avg Duration</p>
                <p className="title">{sessionStats?.avgDuration ? (sessionStats.avgDuration / 60).toFixed(1) : 0}m</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-success">
                <p className="heading">Avg Products</p>
                <p className="title">{sessionStats?.avgProducts ? sessionStats.avgProducts.toFixed(1) : 0}</p>
              </div>
            </div>
            <div className="column is-3">
              <div className="notification is-link">
                <p className="heading">Products/Minute</p>
                <p className="title">
                  {sessionStats?.avgProducts && sessionStats?.avgDuration
                    ? ((sessionStats.avgProducts / (sessionStats.avgDuration / 60))).toFixed(1)
                    : 0}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-full">
            <div className="box">
              <h3 className="title is-5">Session Performance Trends</h3>
              <p className="subtitle is-6">Duration and product count correlation over time</p>
              <canvas id="sessionTrendChart"></canvas>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-one-third">
            <div className="box">
              <h3 className="title is-5">Session Metrics</h3>
              <p className="subtitle is-6">Detailed statistics</p>
              <div className="content">
                <ul>
                  <li>
                    <strong>Longest Session:</strong> {' '}
                    {Math.max(...(sessionStats?.sessions?.map(s => s.duration) || [0])) / 60} minutes
                  </li>
                  <li>
                    <strong>Shortest Session:</strong> {' '}
                    {Math.min(...(sessionStats?.sessions?.map(s => s.duration) || [0])) / 60} minutes
                  </li>
                  <li>
                    <strong>Most Products:</strong> {' '}
                    {Math.max(...(sessionStats?.sessions?.map(s => s.product_count) || [0]))} items
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column is-two-thirds">
            <div className="box">
              <h3 className="title is-5">Session Efficiency Distribution</h3>
              <p className="subtitle is-6">Based on products counted per minute</p>
              <div className="notification is-light">
                <div className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Low Efficiency<br/>(&lt;10 prod/min)</p>
                      <p className="title is-4">
                        {sessionStats?.sessions?.filter(s => 
                          (s.product_count / (s.duration / 60)) < 10
                        ).length || 0}
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Medium Efficiency<br/>(10-20 prod/min)</p>
                      <p className="title is-4">
                        {sessionStats?.sessions?.filter(s => 
                          (s.product_count / (s.duration / 60)) >= 10 && 
                          (s.product_count / (s.duration / 60)) < 20
                        ).length || 0}
                      </p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">High Efficiency<br/>(&gt;20 prod/min)</p>
                      <p className="title is-4">
                        {sessionStats?.sessions?.filter(s => 
                          (s.product_count / (s.duration / 60)) >= 20
                        ).length || 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Vision AI Tab */}
      <div className={selectedTab === 'visionAI' ? '' : 'is-hidden'}>
        {renderVisionAIFilters()}
        
        <div className="columns is-multiline">
          {/* KPI Cards */}
          <div className="column is-12">
            <div className="box">
              <h3 className="title is-5">Detection Performance Overview</h3>
              <div className="columns">
                <div className="column is-3">
                  <div className="notification is-info">
                    <p className="heading">Total Detections</p>
                    <p className="title">{visionMetricsData?.summary?.global_stats?.total_detections || 0}</p>
                  </div>
                </div>
                <div className="column is-3">
                  <div className="notification is-success">
                    <p className="heading">Average Confidence</p>
                    <p className="title">{(visionMetricsData?.summary?.global_stats?.avg_confidence || 0).toFixed(2)}%</p>
                  </div>
                </div>
                <div className="column is-3">
                  <div className="notification is-warning">
                    <p className="heading">Expected In Zone Ratio</p>
                    <p className="title">{(visionMetricsData?.summary?.global_stats?.expected_in_zone_ratio || 0).toFixed(2)}%</p>
                  </div>
                </div>
                <div className="column is-3">
                  <div className="notification is-danger">
                    <p className="heading">Class Changes Detected</p>
                    <p className="title">{visionMetricsData?.summary?.track_changes || 0}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Confidence Timeline */}
          <div className="column is-8">
            <div className="box">
              <h3 className="title is-5">Confidence Timeline</h3>
              <canvas id="confidenceTimelineChart"></canvas>
            </div>
          </div>

          {/* Class Performance */}
          <div className="column is-4">
            <div className="box">
              <h3 className="title is-5">Class Performance</h3>
              <canvas id="classPerformanceChart"></canvas>
            </div>
          </div>

          {/* Resolution Impact */}
          <div className="column is-6">
            <div className="box">
              <h3 className="title is-5">Resolution Impact on Detection</h3>
              <canvas id="resolutionImpactChart"></canvas>
            </div>
          </div>

          {/* Stream Performance */}
          <div className="column is-6">
            <div className="box">
              <h3 className="title is-5">Stream Performance Correlation</h3>
              <canvas id="streamPerformanceChart"></canvas>
            </div>
          </div>

          {/* Track ID Analysis */}
          <div className="column is-12">
            <div className="box">
              <h3 className="title is-5">Track ID Analysis</h3>
              <div className="table-container">
                <table className="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th>Track ID</th>
                      <th>Class Changes</th>
                      <th>Confidence Range</th>
                      <th>Duration</th>
                      <th>Expected In Zone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visionMetricsData?.summary?.track_analysis?.map(track => (
                      <tr key={track.track_id}>
                        <td>{track.track_id}</td>
                        <td>
                          {track.class_changes > 1 && (
                            <span className="tag is-warning">{track.class_changes} changes</span>
                          )}
                          {track.class_changes === 1 && (
                            <span className="tag is-success">Stable</span>
                          )}
                        </td>
                        <td>
                          {track.min_confidence.toFixed(2)}% - {track.max_confidence.toFixed(2)}%
                        </td>
                        <td>{track.duration_ms}ms</td>
                        <td>
                          {track.expected_in_zone ? (
                            <span className="tag is-success">Yes</span>
                          ) : (
                            <span className="tag is-danger">No</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div> {/* Vision AI */}

    </div>
  );
};

export default Dashboard;
